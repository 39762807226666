import React, { Component } from "react";
import { Card, CardTitle, Modal, ModalBody, ModalHeader } from "mdbreact";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Line } from "react-chartjs-2";
import moment from "moment";

import { Link } from "react-router-dom";
import {
  getSemaphoreDetail,
  inserNewAddress,
  inserNewIdController
} from "../../actions/semaphoreActions";

import { OPTIONS_GRAPH_WITH_MULTIAXIS } from "../../../config/graphs";
import renderThStatus from "../../../config/renderThStatus";
import renderThNetwork from "../../../config/renderThNetwork";
import renderThBatteryStatus from "../../../config/renderThBatteryStatus";
import renderThBattery from "../../../config/renderThBattery";
import { Loading } from "../../../config/loading";

class NotificationDetail extends Component {
  state = {
    modalAddress: false,
    newAddress: null,
    loading: true,
    modalIdController: false,
    newIdController: null
  };

  componentDidMount() {
    this.props
      .getSemaphoreDetail(this.props.location.state.semaphore.semaphore_id)
      .then(() => {
        this.setState({ loading: false });
      });
  }
  getMultiAxisChart = (
    data,
    backgroundColor,
    borderColor,
    backgroundColor2,
    borderColor2
  ) => {
    const labels = data.map(d => {
      return moment(d.x, "YYMMDDHHmmss").format("HH:mm");
    });

    const current = data.map(d => {
      return parseInt(d.y_current, 10);
    });

    const voltage = data.map(d => {
      return parseInt(d.y_voltage, 10);
    });

    return {
      labels: labels,
      datasets: [
        {
          yAxisID: "y-tensao",
          label: "TENSÃO (V)",
          data: voltage,
          backgroundColor,
          borderColor,
          lineTension: 0
        },
        {
          yAxisID: "y-corrente",
          label: "CORRENTE (A)",
          data: current,
          backgroundColor: backgroundColor2,
          borderColor: borderColor2,
          lineTension: 0
        }
      ]
    };
  };

  getEnergyChart = (data, backgroundColor, borderColor) => {
    const labels = data.map(d => {
      return moment(d.x, "YYMMDDHHmmss").format("HH:mm");
    });

    const values = data.map(d => {
      return parseInt(d.y, 10);
    });

    return {
      labels: labels,
      datasets: [
        {
          label: "ENERGIA (Wh)",
          data: values,
          backgroundColor,
          borderColor
        }
      ],
      position: "bottom"
    };
  };

  toggle = () => {
    this.setState({
      modalAddress: !this.state.modalAddress,
      newAddress: this.props.detail.address
    });
  };

  openModalIdController = () => {
    this.setState({
      modalIdController: !this.state.modalIdController,
      newIdController: this.props.detail.id_controller
    });
  };

  _handleNewAddress = e => {
    this.setState({
      newAddress: e.target.value
    });
  };

  _handleNewIdController = e => {
    this.setState({
      newIdController: e.target.value
    });
  };

  changeAddress = () => {
    this.setState({ modalAddress: false, loading: true });
    this.props
      .inserNewAddress(
        this.props.location.state.semaphore.semaphore_id,
        this.state.newAddress
      )
      .then(() => {
        this.props.getSemaphoreDetail(this.props.detail.id);
        this.setState({ loading: false });
      });
  };

  changeIdController = () => {
    this.setState({ modalIdController: false, loading: true });
    this.props
      .inserNewIdController(
        this.props.location.state.semaphore.semaphore_id,
        this.state.newIdController
      )
      .then(() => {
        this.props.getSemaphoreDetail(this.props.detail.id);
        this.setState({ loading: false });
      });
  };

  returnBatteryTimeTeft = batterytimeleft => {
    if (batterytimeleft.value === 0) {
      return "Em Carregamento";
    } else {
      return batterytimeleft.value + "h";
    }
  };

  render() {
    const { detail } = this.props;
    return (
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        {Loading(this.state.loading)}
        <div className="container">
          <Card className="card-body">
            <Link to="/semaforo">
              <button
                className="btn btn-back"
                onClick={() => this.setState({ modalForm: true })}
              >
                <i className="fa fa-arrow-left"> </i> Voltar
              </button>
            </Link>
            <CardTitle>Semáforo: {detail.id}</CardTitle>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Status</th>
                  <th style={{ fontWeight: "bold" }}>Rede Elétrica</th>
                  <th style={{ fontWeight: "bold" }}>Bateria</th>
                  <th style={{ fontWeight: "bold" }}>N. Bateria</th>
                  <th style={{ fontWeight: "bold" }}>Id Controladora</th>
                  <th style={{ fontWeight: "bold" }}>Endereço</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    {detail.status ? renderThStatus(detail.status.code) : null}
                  </th>
                  <th>
                    {detail.power_grid
                      ? renderThNetwork(detail.power_grid.code)
                      : null}
                  </th>
                  <th>
                    {detail.batteries
                      ? renderThBatteryStatus(detail.batteries.code)
                      : null}
                  </th>
                  <th>
                    {detail.battery_level
                      ? renderThBattery(detail.battery_level.value)
                      : null}
                  </th>
                  <th>{detail.id_controller}</th>
                  <th>
                    {detail.address}
                    <button
                      className="btn btn-sinape-primary"
                      onClick={this.toggle}
                      style={{
                        backgroundColor: "#999",
                        marginLeft: 8,
                        height: 30,
                        padding: 0,
                        width: 80,
                        marginTop: 0,
                        marginBottom: 0,
                        fontWeight: "bold"
                      }}
                    >
                      <i className="fa fa-edit"> </i> Editar
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
            <div className="semaphore_detail">
              <div className="row">
                <div className="col-md-4">
                  <h1>Outros Detalhes</h1>
                  <div className="item">
                    <i className="fa fa-info"> </i>
                    <h4>Estado:</h4>
                    <p>{detail.status ? detail.status.value : null}</p>
                  </div>
                  <div className="item">
                    <i className="fa fa-battery"> </i>
                    <h4>Bateria: </h4>
                    <p>{detail.batteries ? detail.batteries.value : null}</p>
                  </div>
                  <div className="item">
                    <i className="fa fa-bolt"> </i>
                    <h4>Rede Elétrica: </h4>
                    <p>{detail.power_grid ? detail.power_grid.value : null}</p>
                  </div>
                  <div className="item">
                    <i className="fa fa-podcast"> </i>
                    <h4>Comunicação: </h4>
                    <p>
                      {detail.communication ? detail.communication.value : null}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <h1>Bateria</h1>
                  <div className="item">
                    <i className="fa fa-bolt"> </i>
                    <h4>Tensão: </h4>
                    <p>
                      {detail.battery_voltage
                        ? detail.battery_voltage.value
                        : null}
                      V
                    </p>
                  </div>
                  <div className="item">
                    <i class="fa fa-clock-o" />
                    <h4>Tempo restante: </h4>
                    <p>
                      {detail.battery_time_left
                        ? this.returnBatteryTimeTeft(detail.battery_time_left)
                        : null}
                    </p>
                  </div>
                  <div className="item">
                    <i class="fa fa-refresh" />
                    <h4>Vida útil estimada: </h4>
                    <p>
                      {detail.battery_number_cycles
                        ? parseFloat(
                            detail.battery_number_cycles.value
                          ).toFixed(2)
                        : null}
                      %
                    </p>
                  </div>
                  <div className="item">
                    <i
                      class="fa fa-thermometer-three-quarters"
                      aria-hidden="true"
                    />
                    <h4>Temperatura: </h4>
                    <p>
                      {detail.box_temperature
                        ? parseInt(detail.box_temperature.value, 10)
                        : null}
                      ºC
                    </p>
                  </div>
                  <h1>Controladora</h1>
                  <div className="item">
                    <h4>Id Controladora: </h4>
                    <p>{detail.id_controller}</p>{" "}
                    <button
                      className="btn btn-sinape-primary"
                      onClick={this.openModalIdController}
                      style={{
                        backgroundColor: "#999",
                        marginLeft: 8,
                        padding: 5,
                        marginTop: 0,
                        marginBottom: 0,
                        fontWeight: "bold"
                      }}
                    >
                      <i
                        className="fa fa-edit"
                        style={{ margin: 2, fontSize: 16 }}
                      />{" "}
                    </button>
                  </div>
                  <div className="item">
                    <i className="fa fa-bolt"> </i>
                    <h4>Tensão: </h4>
                    <p>
                      {detail.controller_voltage
                        ? detail.controller_voltage.value
                        : null}
                      V
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <h1>Compartimento</h1>
                  <div className="item">
                    <i class="fa fa-columns" aria-hidden="true" />
                    <h4>Estado: </h4>
                    <p>{detail.open_compartment ? "Aberto" : "Fechado"}</p>
                  </div>
                  <h1>RESUMO DIÁRIO</h1>
                  <div className="item">
                    <h4>Consumo da Rede Elétrica: </h4>
                    <p>
                      {parseFloat(detail.total_energy_consumed).toFixed(1)}
                      Wh
                    </p>
                  </div>
                  <div className="item">
                    <h4>Geração do Painel Solar: </h4>
                    <p>
                      {" "}
                      {parseFloat(detail.total_energy_produced).toFixed(1)}
                      Wh
                    </p>
                  </div>
                </div>
              </div>
              <div className="grafic">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Gráficos</h1>
                  </div>
                  <div className="col-md-6">
                    <h2>BATERIA</h2>
                    <div className="grafic_detail">
                      {detail.battery_graph ? (
                        <Line
                          data={this.getMultiAxisChart(
                            detail.battery_graph,
                            ["rgba(0, 115, 192, 0.5)"],
                            ["rgba(0, 115, 192, 1)"],
                            ["rgba(255, 60, 41, 0.5)"],
                            ["rgba(255, 60, 41, 1)"]
                          )}
                          options={OPTIONS_GRAPH_WITH_MULTIAXIS}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h2>ENERGIA DA BATERIA</h2>
                    <div className="grafic_detail">
                      {detail.battery_energy_graph ? (
                        <Line
                          data={this.getEnergyChart(
                            detail.battery_energy_graph,
                            ["rgba(130, 41, 150, 0.5)"],
                            ["rgba(130, 41, 150, 1)"]
                          )}
                          options={{
                            legend: { position: "bottom" },
                            pan: {
                              enabled: true,
                              mode: "x"
                            },
                            zoom: {
                              enabled: true,
                              mode: "xy"
                            }
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h2>PAINEL SOLAR</h2>
                    <div className="grafic_detail">
                      {detail.solar_graph ? (
                        <Line
                          data={this.getMultiAxisChart(
                            detail.solar_graph,
                            ["rgba(251, 80, 166, 0.5)"],
                            ["rgba(251, 80, 166, 1)"],
                            ["rgba(69, 185, 74, 0.5)"],
                            ["rgba(69, 185, 74, 1)"]
                          )}
                          options={OPTIONS_GRAPH_WITH_MULTIAXIS}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h2>ENERGIA DO PAINEL SOLAR</h2>
                    <div className="grafic_detail">
                      {detail.solar_energy_graph ? (
                        <Line
                          data={this.getEnergyChart(
                            detail.solar_energy_graph,
                            ["rgba(255, 114, 33, 0.5)"],
                            ["rgba(255, 114, 33, 1)"]
                          )}
                          options={{
                            legend: { position: "bottom" },
                            pan: {
                              enabled: true,
                              mode: "x"
                            },
                            zoom: {
                              enabled: true,
                              mode: "xy"
                            }
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h2>REDE ELÉTRICA</h2>
                    <div className="grafic_detail">
                      {detail.network_graph ? (
                        <Line
                          data={this.getMultiAxisChart(
                            detail.network_graph,
                            ["rgba(235, 198, 56, 0.5)"],
                            ["rgba(235, 198, 56, 1)"],
                            ["rgba(102, 212, 232, 0.5)"],
                            ["rgba(102, 212, 232, 1)"]
                          )}
                          options={OPTIONS_GRAPH_WITH_MULTIAXIS}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h2>ENERGIA REDE ELÉTRICA</h2>
                    <div className="grafic_detail">
                      {detail.network_energy_graph ? (
                        <Line
                          data={this.getEnergyChart(
                            detail.network_energy_graph,
                            ["rgba(76, 158, 146, 0.5)"],
                            ["rgba(76, 158, 146, 1)"]
                          )}
                          options={{
                            legend: { position: "bottom" },
                            pan: {
                              enabled: true,
                              mode: "x"
                            },
                            zoom: {
                              enabled: true,
                              mode: "xy"
                            }
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Modal isOpen={this.state.modalAddress} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Digite o novo endereço:
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <label>Endereço</label>
                <input
                  value={this.state.newAddress}
                  onChange={this._handleNewAddress}
                  className="form-control"
                />
              </div>
              <div className="col-md-12">
                <div className="form-group" />
                <button
                  className="btn custom-btn-send pull-right"
                  onClick={this.changeAddress}
                >
                  <i className="fa fa-send"> </i> Enviar
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalIdController}
          toggle={this.openModalIdController}
        >
          <ModalHeader toggle={this.openModalIdController}>
            Digite o novo Id Controladora:
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <label>Id Controladora</label>
                <input
                  value={this.state.newIdController}
                  onChange={this._handleNewIdController}
                  className="form-control"
                />
              </div>
              <div className="col-md-12">
                <div className="form-group" />
                <button
                  className="btn custom-btn-send pull-right"
                  onClick={this.changeIdController}
                >
                  <i className="fa fa-send"> </i> Enviar
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  detail: state.semaphore.detail
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getSemaphoreDetail, inserNewAddress, inserNewIdController },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDetail);
