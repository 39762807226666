import React from "react";
import LoadingScreen from "react-loading-screen";
import logo from "../assets/imgs/logo.png";

export const Loading = loading => {
  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#075979"
      textColor="#075979"
      logoSrc={logo}
      text="Carregando por favor aguarde!"
    />
  );
};
