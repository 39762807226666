import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Card, CardTitle } from "mdbreact";
import { Link } from "react-router-dom";

import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";

import { getReportGeneral } from "../../actions/reportActions";

import logo from "../../../assets/imgs/logo.png";

class ReportGeneral extends Component {
  state = {
    modal: false,
    startDate: moment(),
    endDate: moment(),
    userLogged: ""
  };

  componentDidMount() {
    const {
      location: { state }
    } = this.props;
    this.props.getReportGeneral(
      moment(state.startDate).format("YYYY-MM-DD"),
      moment(state.endDate).format("YYYY-MM-DD")
    );
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ userLogged: user.name });
  }

  printReport = () => {
    const input = document.getElementById("report");
    html2canvas(input).then(canvas => {
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(canvas.toDataURL("image/png"), "JPEG", 5, 5, -134, 0);
      pdf.save("relatorio-geral.pdf");
    });
  };

  formatToHours = time => {
    if (time) {
      return (parseInt(time, 10) / 60 / 60).toFixed(2);
    }
    return "-";
  };

  sumOfHours = () => {
    return this.formatToHours(
      parseInt(this.props.getreportgeneral.tim_outages, 10) +
        parseInt(this.props.getreportgeneral.tim_ac1_crit, 10) +
        parseInt(this.props.getreportgeneral.tim_ac1_prec, 10)
    );
  };

  sumOfSituations = () => {
    return (
      parseInt(this.props.getreportgeneral.num_outages, 10) +
      parseInt(this.props.getreportgeneral.num_ac1_crit, 10) +
      parseInt(this.props.getreportgeneral.num_ac1_prec, 10)
    );
  };

  render() {
    const {
      location: { state },
      getreportgeneral
    } = this.props;
    return (
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        <div className="container">
          <Card className="card-body">
            <Link to="/relatorios">
              <button className="btn btn-back">
                <i className="fa fa-arrow-left"> </i> Voltar
              </button>
            </Link>
            <button className="btn btn-new" onClick={this.printReport}>
              <i className="fa fa-file-pdf-o"> </i> Baixar o PDF
            </button>
            <CardTitle>Relatório</CardTitle>
            <div className="report_detail" id="report">
              <div className="row">
                <div className="col-md-4">
                  <img src={logo} className="logo" alt="Logo" />
                </div>
                <div className="col-md-4" />
                <div className="col-md-4">
                  <h1>RELATÓRIO GERAL</h1>
                  <p>
                    <strong>Período:</strong>{" "}
                    {moment(state.startDate).format("DD/MM/YYYY")} à{" "}
                    {moment(state.endDate).format("DD/MM/YYYY")}
                  </p>
                  <p>
                    <strong>Usuário:</strong> {this.state.userLogged}
                  </p>
                </div>
              </div>
              <hr />
              <div className="energy_quality">
                <h1>QUALIDADE DA ENERGIA DA REDE ELÉTRICA</h1>
                <div className="row">
                  <div className="col-md-12">
                    <h4>NÚMERO DE OUTAGES:</h4>
                    <p>
                      No período destacado houve ocorrência de{" "}
                      <strong>{getreportgeneral.num_outages}</strong> outages
                      que poderiam ter impossibilitado o funcionamento dos
                      semáforos , durante{" "}
                      {moment()
                        .startOf("day")
                        .seconds(getreportgeneral.tim_outages + 0)
                        .format("HH:mm:ss")}{" "}
                      horas.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <h4>NÚMERO DE TENSÕES CRÍTICAS:</h4>
                    <p>
                      No período destacado houve ocorrência de{" "}
                      <strong>{getreportgeneral.num_ac1_crit}</strong> tensões
                      críticas que poderiam gerar prejuízo ao funcionamento dos
                      semáforos, durante{" "}
                      {moment()
                        .startOf("day")
                        .seconds(getreportgeneral.tim_ac1_crit + 0)
                        .format("HH:mm:ss")}{" "}
                      horas.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <h4>NÚMERO DE TENSÕES PRECÁRIAS:</h4>
                    <p>
                      No período destacado houve ocorrência de{" "}
                      <strong>{getreportgeneral.num_ac1_prec}</strong> tensões
                      precárias que poderiam gerar prejuízo ao funcionamento dos
                      semáforos, durante{" "}
                      {moment()
                        .startOf("day")
                        .seconds(getreportgeneral.tim_ac1_prec + 0)
                        .format("HH:mm:ss")}{" "}
                      horas.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <h4>
                      DURANTE O PERÍODO DE{" "}
                      {moment(state.startDate).format("DD/MM/YYYY")} À{" "}
                      {moment(state.endDate).format("DD/MM/YYYY")} HOUVE{" "}
                      {this.sumOfSituations()} SITUAÇÕES QUE PODERIAM PREJUDICAR
                      OU IMPOSSIBILITARAR O FUNCIONAMENTO DO SEMÁFORO, PELA REDE
                      ELÉTRICA, POR{" "}
                      {moment()
                        .startOf("day")
                        .seconds(
                          getreportgeneral.tim_outages +
                            getreportgeneral.tim_ac1_crit +
                            getreportgeneral.tim_ac1_prec
                        )
                        .format("HH:mm:ss")}{" "}
                      HORAS.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="energy_generation">
                <h1>GERAÇÃO E ECONOMIA DE ENERGIA</h1>
                <div className="row">
                  <div className="col-md-4">
                    <h4>ENERGIA GERADA:</h4>
                    <p>
                      No período destacado o sistema gerou:{" "}
                      {parseFloat(getreportgeneral.gen_energy).toFixed(2)}
                      Wh.
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h4>ENERGIA CONSUMIDA:</h4>
                    <p>
                      No período destacado o sistema consumiu:{" "}
                      {parseFloat(getreportgeneral.con_energy).toFixed(2)}
                      Wh.
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h4>ECONOMIA DE ENERGIA: </h4>
                    <p>
                      No período destacado o sistema economizou:{" "}
                      {parseFloat(getreportgeneral.eco_energy * 100).toFixed(2)}
                      %{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-new_bottom" onClick={this.printReport}>
              <i className="fa fa-file-pdf-o"> </i> Baixar o PDF
            </button>
          </Card>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  getreportgeneral: state.report.getreportgeneral
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getReportGeneral }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportGeneral);
