const INITIAL_STATE = {
  list: [],
  success: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN_FETCHED":
      localStorage.setItem("token", action.payload["api_token"]);
      localStorage.setItem(
        "user",
        JSON.stringify({
          email: action.payload["email"],
          id: action.payload["_id"],
          name: action.payload["name"],
          role: action.payload["role"]
        })
      );
      return { ...state, success: action.payload };
    case "GET_USER":
      return { ...state, list: action.payload };
    default:
      return state;
  }
};
