import api from "../../config/axios";

import OauthHelper from "../../config/OauthHelper";
const idUser = OauthHelper.getUserIdBase64();

export const getReportDetail = id => {
  const AuthString = `Basic ${idUser}`;
  return dispatch =>
    api
      .get(`api/reports/${id}`, {
        headers: { Authorization: AuthString }
      })
      .then(resp => {
        dispatch({
          type: "GET_REPORT_DETAIL",
          payload: resp.data
        });
      });
};

export const getReportByDate = (id, startDate, endDate) => {
  const AuthString = `Basic ${idUser}`;
  return dispatch =>
    api
      .get(`/api/report/${id}?date_from=${startDate}&date_to=${endDate}`, {
        headers: { Authorization: AuthString }
      })
      .then(resp => {
        dispatch({
          type: "GET_REPORT_BY_DATE",
          payload: resp.data
        });
      });
};

export const getReportGeneral = (startDate, endDate) => {
  const AuthString = `Basic ${idUser}`;
  return dispatch =>
    api
      .get(
        `/api/report/reportgeneral?date_from=${startDate}&date_to=${endDate}`,
        {
          headers: { Authorization: AuthString }
        }
      )
      .then(resp => {
        dispatch({
          type: "GET_REPORT_GENERAL",
          payload: resp.data
        });
      });
};
