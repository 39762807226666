class OauthHelper {
  getUserID() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.id) {
      return user.id;
    }
    return "";
  }

  getUserIdBase64() {
    return btoa(this.getUserID());
  }
}

export default new OauthHelper();
