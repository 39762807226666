import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Card, CardTitle } from "mdbreact";
import { Link } from "react-router-dom";

import { sendUser } from "../../actions/usersActions";
import { userValidate as validate } from "../../../config/validate";
import {
  renderInput,
  renderInputPassword,
  renderSelect
} from "../../../config/renderInput";

class FormAddUser extends Component {
  onSubmit = values => {
    this.props.sendUser(values).then(() => this.goBack());
  };

  goBack = () => {
    this.props.history.push("/usuarios");
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <div className="container">
          <Card className="card-body">
            <Link to="/usuarios">
              <button className="btn btn-back">
                <i className="fa fa-arrow-left"> </i> Voltar
              </button>
            </Link>
            <CardTitle>Novo Usuário</CardTitle>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nome</label>
                    <Field name="name" component={renderInput} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>E-mail</label>
                    <Field name="email" component={renderInput} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Perfil</label>
                    <Field name="role" component={renderSelect} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Senha</label>
                    <Field name="password" component={renderInputPassword} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <button
                      className="btn custom-btn-send pull-right"
                      type="submit"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
}

let form = reduxForm({ form: "FormUser", validate })(FormAddUser);
form = connect(
  state => ({}),
  { sendUser }
)(form);
export default form;
