import React, { Component } from "react";
import { Card, CardTitle, Modal, ModalBody, ModalHeader } from "mdbreact";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";

import { Loading } from "../../../config/loading";

import {
  getUser,
  sendUserDelete,
  AuthString
} from "../../actions/usersActions";
import api from "../../../config/axios";

class User extends Component {
  state = {
    loading: true,
    modal: false,
    _id: "",
    user: "",
    name: "",
    email: "",
    role: "",
    password: ""
  };
  componentDidMount() {
    this.props.getUser().then(() => {
      this.setState({ loading: false });
    });
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.role !== "ADMIN") {
      this.props.history.push("/");
      toast.warn("Você não permissão para acessa essa página :(");
    }
  }

  newUser = () => {
    this.props.history.push({
      pathname: `/novousuario/`
    });
  };

  toggle = user => {
    this.setState({
      modal: !this.state.modal,
      _id: user._id,
      name: user.name,
      email: user.email,
      role: user.role,
      password: user.password
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit(e, _id, name, email, role, password) {
    e.preventDefault();
    api
      .put(
        `api/user/${_id}`,
        { name, email, role, password },
        { headers: { Authorization: AuthString } }
      )
      .then(() => {
        this.setState({ modal: false });
        toast.success("Usuário atualizado com sucesso");
        this.props.getUser();
      });
  }

  render() {
    const { list, sendUserDelete } = this.props;
    const { _id, name, email, role, password } = this.state;
    return (
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        {Loading(this.state.loading)}
        <div className="container">
          <Card className="card-body">
            <button className="btn btn-new" onClick={this.newUser}>
              <i className="fa fa-plus"> </i> Novo Usuário
            </button>
            <CardTitle>Usuários</CardTitle>
            <table className="table table-striped">
              <thead>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Nome</td>
                  <td style={{ fontWeight: "bold" }}>E-mail</td>
                  <td style={{ fontWeight: "bold" }}>Perfil</td>
                  <td style={{ fontWeight: "bold" }}>Acões</td>
                </tr>
              </thead>
              <tbody>
                {list.map((item, key) => (
                  <tr key={key}>
                    <th>{item.name}</th>
                    <th>{item.email}</th>
                    <th>{item.role}</th>
                    <th width="150">
                      <button
                        className="btn btn-sinape-secondary"
                        onClick={() => this.toggle(item)}
                        style={{
                          backgroundColor: "#999",
                          marginLeft: 8,
                          height: 30,
                          padding: 0,
                          width: 40,
                          marginTop: 0,
                          marginBottom: 0
                        }}
                      >
                        <i className="fa fa-edit"> </i>
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => sendUserDelete(item._id)}
                        style={{
                          backgroundColor: "#999",
                          marginLeft: 8,
                          height: 30,
                          padding: 0,
                          width: 40,
                          marginTop: 0,
                          marginBottom: 0
                        }}
                      >
                        <i className="fa fa-trash"> </i>
                      </button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.setState({ modal: false })}
          fullHeight
          position="bottom"
        >
          <ModalHeader toggle={() => this.setState({ modal: false })}>
            Usuário: {this.state.name ? this.state.name : ""}
          </ModalHeader>
          <ModalBody>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nome</label>
                    <input
                      name="name"
                      value={this.state.name}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>E-mail</label>
                    <input
                      name="email"
                      value={this.state.email}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Perfil</label>
                    <select
                      className="form-control"
                      name="role"
                      value={this.state.role}
                      onChange={this.handleChange}
                    >
                      <option value="ADMIN">ADMIN</option>
                      <option value="OPERADOR">OPERADOR</option>
                      <option value="GESTOR">GESTOR</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Senha</label>
                    <input
                      type="password"
                      name="password"
                      value={this.state.password}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <button
                      className="btn custom-btn-send pull-right"
                      onClick={e =>
                        this.onSubmit(e, _id, name, email, role, password)
                      }
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  list: state.users.list
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUser, sendUserDelete }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);
