export const userLoginValidate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Campo email é obrigatório";
  }
  if (!values.email) {
    errors.email = "Campo e-mail é obrigatório";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Endereço de email invalido";
  }

  if (!values.password) {
    errors.password = "Campo é senha obrigatório";
  }
  return errors;
};

export const userValidate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Campo nome é obrigatório";
  }
  if (!values.email) {
    errors.email = "Campo e-mail é obrigatório";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Endereço de email invalido";
  }
  if (!values.role) {
    errors.role = "Campo perfil é obrigatório";
  }

  if (!values.password) {
    errors.password = "Campo é senha obrigatório";
  }
  return errors;
};
