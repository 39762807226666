import React from "react";

const renderThNetwork = code => {
  if (code === 200) {
    return (
      <img
        src={require("../assets/imgs/circle_green.png")}
        width="25"
        alt="Network"
      />
    );
  } else if (code === 201 || code === 204) {
    return (
      <div>
        <img
          src={require("../assets/imgs/circle_yellow.png")}
          width="25"
          alt="Network"
        />
      </div>
    );
  } else if (code === 202 || code === 205) {
    return (
      <div>
        <img
          src={require("../assets/imgs/circle_yellow.png")}
          width="25"
          alt="Network"
        />
      </div>
    );
  } else if (code === 203) {
    return (
      <div>
        <img
          src={require("../assets/imgs/circle_red.png")}
          width="25"
          alt="Network"
        />
      </div>
    );
  }
};

export default renderThNetwork;
