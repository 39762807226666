import React from "react";

const renderThBatteryStatus = code => {
  if (code === 100) {
    return (
      <img
        src={require("../assets/imgs/circle_green.png")}
        width="25"
        alt="Status"
      />
    );
  } else if (code === 101) {
    return (
      <div>
        <img
          src={require("../assets/imgs/circle_yellow.png")}
          width="25"
          alt="Status"
        />
      </div>
    );
  } else if (code === 102) {
    return (
      <div>
        <img
          src={require("../assets/imgs/circle_yellow.png")}
          width="25"
          alt="Status"
        />
      </div>
    );
  } else if (code === 103) {
    return (
      <div>
        <img
          src={require("../assets/imgs/circle_red.png")}
          width="25"
          alt="Status"
        />
      </div>
    );
  }
};

export default renderThBatteryStatus;
