const INITIAL_STATE = {
  list: [],
  count: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_NOTIFICATION":
      return { ...state, list: action.payload, count: action.payload.length };
    default:
      return state;
  }
};
