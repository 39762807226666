import api from "../../config/axios";
import { toast } from "react-toastify";

import { reset as resetForm } from "redux-form";
import OauthHelper from "../../config/OauthHelper";
const idUser = OauthHelper.getUserIdBase64();
export const AuthString = `Basic ${idUser}`;

export function login(values) {
  return dispatch =>
    api
      .post("api/user/login", values)
      .then(resp => {
        dispatch({
          type: "LOGIN_FETCHED",
          payload: resp.data
        });
      })
      .catch(e => {
        toast.error(`${e.response.data.error}`);
      });
}

export const getUser = () => {
  return dispatch =>
    api
      .get("api/user/", { headers: { Authorization: AuthString } })
      .then(resp => {
        dispatch({
          type: "GET_USER",
          payload: resp.data
        });
      });
};

export const sendUser = values => {
  return dispatch =>
    api
      .post(
        "api/user/",
        { ...values },
        { headers: { Authorization: AuthString } }
      )
      .then(resp => {
        dispatch(getUser(), resetForm("FormUser"));
      })
      .catch(e => {
        if (e.response) {
          toast.warn(`${e.response.data.error}`);
        }
      });
};

export const sendUserUpdate = (id, values) => {
  return dispatch =>
    api.put(
      `api/user/${id}`,
      { ...values },
      { headers: { Authorization: AuthString } }
    );
};

export const sendUserDelete = id => {
  return dispatch =>
    api
      .delete(`api/user/${id}`, { headers: { Authorization: AuthString } })
      .then(resp => {
        dispatch(getUser());
      });
};
