import React from "react";

const renderThBattery = code => {
  let renderJsxBattery = (src, code) => {
    return (
      <div>
        {code > 0 ? <span>{parseInt(Math.ceil(code * 100), 10)}% </span> : null}
        <img src={src} alt={code} width="25" />
      </div>
    );
  };

  let renderBattery = code => {
    if (code <= 1 && code > 0.7) {
      return renderJsxBattery(
        require("../assets/imgs/status_battery_100.png"),
        code
      );
    }
    if (code <= 0.7 && code > 0.35) {
      return renderJsxBattery(
        require("../assets/imgs/status_battery_50.png"),
        code
      );
    }
    if (code <= 0.35 && code > 0.1) {
      return renderJsxBattery(
        require("../assets/imgs/status_battery_10.png"),
        code
      );
    }
    if (code <= 0.1) {
      return renderJsxBattery(
        require("../assets/imgs/status_battery_0.png"),
        code
      );
    }
  };
  return renderBattery(code);
};

export default renderThBattery;
