import React, { Component } from "react";
import { Footer, Container } from "mdbreact";

import logo from "../../../assets/imgs/logo_fuse_white.png";

export default class FooterPage extends Component {
  render() {
    return (
      <div>
        <Footer color="btn-sinape-primary" className="font-small">
          <div className="footer-copyright py-4">
            <Container container>
              <div className="row">
                <div className="col-md-6">
                  <h6 className="copyright py-1">
                    &copy; {new Date().getFullYear()} Copyright SINAPE
                    Sinalização Viária
                  </h6>
                </div>
                <div className="col-md-6">
                  <h6 className="copyright text-right py-1">
                    Desenvolvido por: <img src={logo} width="50" alt="Logo" />
                  </h6>
                </div>
              </div>
            </Container>
          </div>
        </Footer>
      </div>
    );
  }
}
