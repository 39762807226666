import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import socket from "socket.io-client";
import api from "../../../config/axios";

import {
  Navbar,
  NavbarToggler,
  Collapse,
  NavbarBrand,
  NavbarNav,
  NavItem,
  NavLink
} from "mdbreact";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import logo from "../../../assets/imgs/logo.png";
import { getNotification, AuthString } from "../../actions/notificationActions";

export default class HeaderPage extends Component {
  state = {
    collapse: false,
    isWideEnough: false,
    isAdmin: "",
    count: ""
  };

  onClick = () => {
    this.setState({
      collapse: !this.state.collapse
    });
  };

  async componentDidMount() {
    // this.subscribeToEvents();
    // this.props.getNotification();
    api
      .get("api/notification/count", { headers: { Authorization: AuthString } })
      .then(data => {
        console.log(data.data);
        this.setState({ count: data.data.number_of_notifications });
      });
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.setState({ isAdmin: user.role });
    }
  }

  subscribeToEvents = () => {
    const io = socket.connect("http://localhost:8081/");
    io.on("connect", data => {
      console.log(data);
    });
  };

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.reload();
  };

  renderMenuUser = () => {
    if (this.state.isAdmin === "ADMIN") {
      return (
        <NavItem>
          <NavLink to="/usuarios">USUÁRIOS</NavLink>
        </NavItem>
      );
    }
  };

  renderMenuReport = () => {
    if (this.state.isAdmin === "GESTOR" || this.state.isAdmin === "ADMIN") {
      return (
        <NavItem>
          <NavLink to="/relatorios">RELATÓRIOS</NavLink>
        </NavItem>
      );
    }
  };

  render() {
    const { count } = this.state;
    return (
      <div>
        <Navbar expand="lg" light>
          <div className="container">
            <NavbarBrand>
              <Link to="/admin">
                <img src={logo} width="60" alt="Logo" />
              </Link>
            </NavbarBrand>
            {!this.state.isWideEnough && (
              <NavbarToggler onClick={this.onClick} />
            )}
            <Collapse isOpen={this.state.collapse} navbar>
              <NavbarNav right>
                <NavItem>
                  <NavLink to="/admin">INÍCIO</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/semaforo">SEMÁFORO</NavLink>
                </NavItem>
                <NavItem>
                  <div className="notification">{count}</div>
                  <NavLink to="/notificacoes">NOTIFICAÇÕES</NavLink>
                </NavItem>
                {this.renderMenuReport()}
                {this.renderMenuUser()}
                <NavItem>
                  <Link className="nav-link" to="/" onClick={this.logout}>
                    SAIR
                  </Link>
                </NavItem>
              </NavbarNav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}
// const mapStateToProps = state => ({
//   count: state.notification.count
// });
// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ getNotification }, dispatch);
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(HeaderPage));
