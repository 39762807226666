import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import logo from "../../../assets/imgs/logo.png";
import logoFuse from "../../../assets/imgs/logo-fuse.jpg";

import { Card, CardBody, CardHeader, CardText } from "mdbreact";
import { login } from "../../actions/usersActions";

import { userLoginValidate as validate } from "../../../config/validate";
import { renderInput, renderInputPassword } from "../../../config/renderInput";

class LoginPage extends Component {
  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.history.push("/admin");
    }
  }

  onSubmit = values => {
    this.props.login(values).then(response => {
      this.props.history.push("/admin");
      window.location.reload();
    });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <Card style={{ marginTop: "10rem" }}>
                <CardHeader color="btn-sinape-secondary" tag="h3">
                  Entre com e-mail e senha
                </CardHeader>
                <CardBody>
                  <CardText>
                    <div className="row">
                      <div className="col-md-4">
                        <img src={logo} width="200" alt="Logo" />
                      </div>
                      <div className="col-md-8">
                        <form onSubmit={handleSubmit(this.onSubmit)}>
                          <p className="h4 text-center mb-4" />
                          <label
                            htmlFor="defaultFormLoginEmailEx"
                            className="grey-text"
                          >
                            E-mail
                          </label>
                          <Field
                            type="text"
                            name="email"
                            component={renderInput}
                            className="form-control"
                          />
                          <br />
                          <label
                            htmlFor="defaultFormLoginPasswordEx"
                            className="grey-text"
                          >
                            Senha
                          </label>
                          <Field
                            type="password"
                            name="password"
                            component={renderInputPassword}
                            className="form-control"
                          />
                          <div className="text-center mt-4">
                            <button
                              className="btn custom-btn-send pull-right"
                              type="submit"
                            >
                              <i className="fa fa-arrow-right" /> Logar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </CardText>
                </CardBody>
              </Card>
            </div>
            <div className="col-md-3" />
          </div>
          <div className="developer">
            <h6>Desenvolvido por:</h6>
            <img src={logoFuse} alt="Logo Fuse" />
          </div>
        </div>
      </div>
    );
  }
}
LoginPage = reduxForm({ form: "LoginPage", validate })(LoginPage);
const mapDispatchToProps = dispatch => bindActionCreators({ login }, dispatch);
export default connect(
  null,
  mapDispatchToProps
)(LoginPage);
