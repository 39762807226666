import api from "../../config/axios";

import OauthHelper from "../../config/OauthHelper";
const idUser = OauthHelper.getUserIdBase64();
const AuthString = `Basic ${idUser}`;

export const getSemaphore = () => {
  return dispatch =>
    api
      .get("api/semaphore/", { headers: { Authorization: AuthString } })
      .then(resp => {
        dispatch({
          type: "GET_SEMAPHORE",
          payload: resp.data
        });
      });
};

export const getSemaphoreDetail = id => {
  return dispatch =>
    api
      .get(`api/semaphore/${id}`, {
        headers: { Authorization: AuthString }
      })
      .then(resp => {
        dispatch({
          type: "GET_SEMAPHORE_DETAIL",
          payload: resp.data
        });
      });
};

export const inserNewAddress = (semaphoreId, address) => {
  return dispatch =>
    api
      .put(
        `api/semaphore/${semaphoreId}`,
        { address: address },
        {
          headers: { Authorization: AuthString }
        }
      )
      .then(resp => {});
};

export const inserNewIdController = (semaphoreId, id_controller) => {
  return dispatch =>
    api
      .put(
        `api/semaphore/${semaphoreId}`,
        { id_controller: id_controller },
        {
          headers: { Authorization: AuthString }
        }
      )
      .then(resp => {});
};
