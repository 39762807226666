import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import userReducer from "../components/reducers/usersReducer";
import semaphoreReducer from "../components/reducers/semaphoreReducer";
import notificationReducer from "../components/reducers/notificationReducer";
import reportReducer from "../components/reducers/reportReducer";

const rootReducer = combineReducers({
  form: formReducer,
  users: userReducer,
  semaphore: semaphoreReducer,
  notification: notificationReducer,
  report: reportReducer
});

export default rootReducer;
