import React, { Component } from "react";
import { Card, CardTitle } from "mdbreact";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { Loading } from "../../../config/loading";
import { getNotification } from "../../actions/notificationActions";

class Notification extends Component {
  state = {
    loading: true
  };
  componentDidMount() {
    this.props.getNotification().then(() => {
      this.setState({ loading: false });
    });
    if (this.props.list.length === 0) {
      this.setState({ loading: false });
    }
  }
  pushSemaphoreDetail = item => {
    this.props.history.push({
      pathname: `/notificacaodetalhe/${item.semaphore_id}`,
      state: {
        semaphore: item
      }
    });
  };
  render() {
    const { list } = this.props;

    return (
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        {Loading(this.state.loading)}
        <div className="container">
          <Card className="card-body">
            <CardTitle>Notificações</CardTitle>
            <table className="table table-striped">
              <thead>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Semáforo</td>
                  <td style={{ fontWeight: "bold" }}>Data/Hora</td>
                  <td style={{ fontWeight: "bold" }}>Notificação</td>
                  <td style={{ fontWeight: "bold" }}>Endereço</td>
                  <td style={{ fontWeight: "bold" }}>Ações</td>
                </tr>
              </thead>
              <tbody>
                {list.map((item, key) => (
                  <tr key={key}>
                    <th>{item.semaphore_id}</th>
                    <th>
                      {moment(item.date, "YYYY-MM-DD HH:mm:ss").format(
                        "DD/MM/YYYY hh:mm"
                      )}
                    </th>
                    <th>{item.description}</th>
                    <th>{item.address}</th>
                    <th width="100">
                      <button
                        className="btn btn-sinape-primary"
                        onClick={() => this.pushSemaphoreDetail(item)}
                        style={{
                          backgroundColor: "#999",
                          marginLeft: 8,
                          height: 30,
                          padding: 0,
                          width: 40,
                          marginTop: 0,
                          marginBottom: 0
                        }}
                      >
                        <i className="fa fa-search"> </i>
                      </button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  list: state.notification.list
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getNotification }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
