import React, { Component } from "react";
import { Card, CardTitle, Modal, ModalBody, ModalHeader } from "mdbreact";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { getSemaphoreDetail } from "../../actions/semaphoreActions";
import { getReportByDate } from "../../actions/reportActions";

import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import moment from "moment";

import logo from "../../../assets/imgs/logo.png";

class ReportDetail extends Component {
  state = {
    modal: false,
    startDate: moment(),
    endDate: moment(),
    userLogged: ""
  };

  componentDidMount() {
    const {
      location: { state }
    } = this.props;
    this.props.getSemaphoreDetail(state.semaphore.id);
    this.props.getReportByDate(
      state.semaphore.id,
      moment(state.startDate).format("YYYY-MM-DD"),
      moment(state.endDate).format("YYYY-MM-DD")
    );
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ userLogged: user.name });
  }

  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };

  formatToHours = time => {
    if (time) {
      return (parseInt(time, 10) / 60 / 60).toFixed(2);
    }
    return "-";
  };

  formatToDecimal = number => {
    if (number) {
      return parseFloat(number).toFixed(2);
    }
    return "-";
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  printReport = () => {
    const input = document.getElementById("report");
    html2canvas(input).then(canvas => {
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(canvas.toDataURL("image/png"), "JPEG", 5, 5, -134, 0);
      pdf.save("relatorio.pdf");
    });
  };

  sumOfSituations = () => {
    return (
      parseInt(this.props.getreportbydate.number_of_reads_in_outages, 10) +
      parseInt(
        this.props.getreportbydate.number_of_reads_in_critical_voltage,
        10
      ) +
      parseInt(
        this.props.getreportbydate.number_of_reads_in_precarious_voltage,
        10
      )
    );
  };

  sumOfHours = () => {
    return this.formatToHours(
      parseInt(this.props.getreportbydate.time_in_outages, 10) +
        parseInt(this.props.getreportbydate.time_in_critical_voltage, 10) +
        parseInt(this.props.getreportbydate.time_in_precarious_voltage, 10)
    );
  };

  render() {
    const {
      location: { state },
      getreportbydate
    } = this.props;
    return (
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        <div className="container">
          <Card className="card-body">
            <Link to="/relatorios">
              <button
                className="btn btn-back"
                onClick={() => this.setState({ modalForm: true })}
              >
                <i className="fa fa-arrow-left"> </i> Voltar
              </button>
            </Link>
            <button className="btn btn-new" onClick={this.printReport}>
              <i className="fa fa-file-pdf-o"> </i> Baixar o PDF
            </button>
            <CardTitle>Relatório</CardTitle>
            <div className="report_detail" id="report">
              <div className="row">
                <div className="col-md-4">
                  <img src={logo} className="logo" alt="Logo" />
                </div>
                <div className="col-md-4" />
                <div className="col-md-4">
                  <h1>RELATÓRIO INDIVIDUAL GERAL</h1>
                  <p>
                    <strong>Período:</strong>{" "}
                    {moment(state.startDate).format("DD/MM/YYYY")} à{" "}
                    {moment(state.endDate).format("DD/MM/YYYY")}
                  </p>
                  <p>
                    <strong>Semáforo:</strong> {state.semaphore.id}
                  </p>
                  <p>
                    <strong>Id Controladora:</strong>{" "}
                    {state.semaphore.id_controller}
                  </p>
                  <p>
                    <strong>Usuário:</strong> {this.state.userLogged}
                  </p>
                </div>
              </div>
              <hr />
              <div className="info_system">
                <h1>INFORMAÇÕES DO SISTEMA</h1>
                <div className="row">
                  <div className="col-md-4">
                    <h4>Instalação:</h4>
                    <p>
                      {state.semaphore.month_of_installation}/
                      {state.semaphore.year_of_installation}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h4>Local:</h4>
                    <p>{state.semaphore.address}</p>
                  </div>
                  <div className="col-md-4">
                    <h4>Tensão do Sistema:</h4>
                    <p>{state.semaphore.specified_network_voltage}V</p>
                  </div>
                </div>
              </div>
              <div className="energy_quality">
                <h1>QUALIDADE DA ENERGIA DA REDE ELÉTRICA</h1>
                <div className="row">
                  <div className="col-md-12">
                    <h4>NÚMERO DE OUTAGES:</h4>
                    <p>
                      No período destacado houve ocorrência de{" "}
                      <strong>
                        {getreportbydate.number_of_reads_in_outages}
                      </strong>{" "}
                      outages que poderiam ter impossibilitado o funcionamento
                      do semáforo {state.semaphore.id}, durante{" "}
                      {moment()
                        .startOf("day")
                        .seconds(getreportbydate.time_in_outages + 0)
                        .format("HH:mm:ss")}{" "}
                      horas.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <h4>NÚMERO DE TENSÕES CRÍTICAS:</h4>
                    <p>
                      No período destacado houve ocorrência de{" "}
                      <strong>
                        {getreportbydate.number_of_reads_in_critical_voltage}
                      </strong>{" "}
                      tensões críticas que poderiam gerar prejuízo ao
                      funcionamento do semáforo {state.semaphore.id}, durante{" "}
                      {moment()
                        .startOf("day")
                        .seconds(getreportbydate.time_in_critical_voltage + 0)
                        .format("HH:mm:ss")}{" "}
                      horas.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <h4>NÚMERO DE TENSÕES PRECÁRIAS:</h4>
                    <p>
                      No período destacado houve ocorrência de{" "}
                      <strong>
                        {getreportbydate.number_of_reads_in_precarious_voltage}
                      </strong>{" "}
                      tensões precárias que poderiam gerar prejuízo ao
                      funcionamento do semáforo {state.semaphore.id}, durante{" "}
                      {moment()
                        .startOf("day")
                        .seconds(getreportbydate.time_in_precarious_voltage + 0)
                        .format("HH:mm:ss")}{" "}
                      horas.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <h4>
                      DURANTE O PERÍODO DE{" "}
                      {moment(state.startDate).format("DD/MM/YYYY")} À{" "}
                      {moment(state.endDate).format("DD/MM/YYYY")} HOUVE{" "}
                      {this.sumOfSituations()} SITUAÇÕES QUE PODERIAM PREJUDICAR
                      OU IMPOSSIBILITARAR O FUNCIONAMENTO DO SEMÁFORO, PELA REDE
                      ELÉTRICA, POR{" "}
                      {moment()
                        .startOf("day")
                        .seconds(
                          getreportbydate.time_in_outages +
                            getreportbydate.time_in_critical_voltage +
                            getreportbydate.time_in_precarious_voltage
                        )
                        .format("HH:mm:ss")}{" "}
                      HORAS.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="energy_generation">
                <h1>GERAÇÃO E ECONOMIA DE ENERGIA</h1>
                <div className="row">
                  <div className="col-md-4">
                    <h4>ENERGIA GERADA:</h4>
                    <p>
                      No período destacado o sistema gerou:{" "}
                      {parseFloat(getreportbydate.generated_energy).toFixed(2)}
                      Wh.
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h4>ENERGIA CONSUMIDA:</h4>
                    <p>
                      No período destacado o sistema consumiu:{" "}
                      {parseFloat(getreportbydate.consumed_energy).toFixed(2)}
                      Wh.
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h4>ECONOMIA DE ENERGIA: </h4>
                    <p>
                      No período destacado o sistema economizou:{" "}
                      {parseFloat(getreportbydate.energy_saving * 100).toFixed(
                        2
                      )}
                      %{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="report">
                <h1>RESUMO DIÁRIO</h1>
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>Data</th>
                      <th style={{ fontWeight: "bold" }}>Leituras em Outage</th>
                      <th style={{ fontWeight: "bold" }}>Tempo em Outages</th>
                      <th style={{ fontWeight: "bold" }}>
                        Leituras em Tensão Crítica
                      </th>
                      <th style={{ fontWeight: "bold" }}>
                        Tempo em Tensão Crítica
                      </th>
                      <th style={{ fontWeight: "bold" }}>
                        Leituras em Tensão Precária
                      </th>
                      <th style={{ fontWeight: "bold" }}>
                        Tempo em Tensão Precária
                      </th>
                      <th style={{ fontWeight: "bold" }}>
                        Energia Gerada (Wh)
                      </th>
                      <th style={{ fontWeight: "bold" }}>
                        Economia de Energia (%)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getreportbydate.report_data
                      ? getreportbydate.report_data.map((item, key) => (
                          <tr key={key}>
                            <th>
                              {moment(item.ts, "YYMMDD").format("DD/MM/YYYY")}
                            </th>
                            <th>{item.number_of_reads_in_outages}</th>
                            <th>
                              {moment()
                                .startOf("day")
                                .seconds(item.time_in_outages)
                                .format("HH:mm:ss")}
                            </th>
                            <th>{item.number_of_reads_in_critical_voltage}</th>
                            <th>
                              {moment()
                                .startOf("day")
                                .seconds(item.time_in_critical_voltage)
                                .format("HH:mm:ss")}
                            </th>
                            <th>
                              {item.number_of_reads_in_precarious_voltage}
                            </th>
                            <th>
                              {moment()
                                .startOf("day")
                                .seconds(item.time_in_precarious_voltage)
                                .format("HH:mm:ss")}
                            </th>
                            <th>
                              {this.formatToDecimal(item.generated_energy)}
                            </th>
                            <th>
                              {this.formatToDecimal(item.energy_saving) * 100}
                            </th>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <button className="btn btn-new_bottom" onClick={this.printReport}>
              <i className="fa fa-file-pdf-o"> </i> Baixar o PDF
            </button>
          </Card>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Selecione um período:</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <label>Inicio</label>
                <DatePicker
                  locale="pt-br"
                  dateFormat="DD/MM/YYYY"
                  className="form-control"
                  onChange={this.handleStartDateChange}
                  selected={this.state.startDate}
                />
              </div>
              <div className="col-md-6">
                <label>Fim</label>
                <DatePicker
                  locale="pt-br"
                  dateFormat="DD/MM/YYYY"
                  className="form-control"
                  onChange={this.handleEndDateChange}
                  selected={this.state.endDate}
                />
              </div>
              <div className="col-md-12">
                <div className="form-group" />
                <button className="btn custom-btn-send pull-right">
                  <i className="fa fa-file-pdf-o"> </i> Gerar Relatório
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  detail: state.semaphore.detail,
  getreportbydate: state.report.getreportbydate
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getSemaphoreDetail, getReportByDate }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportDetail);
