import React from "react";

export const renderInput = ({
  input: { onChange, ...restInput },
  meta: { touched, error, warning }
}) => {
  return (
    <div>
      <input onChange={onChange} {...restInput} className="form-control" />
      {touched &&
        ((error && (
          <div
            style={{
              color: "#fff",
              borderBottomRightRadius: 5,
              borderBottomLeftRadius: 5,
              backgroundColor: "#CC0000",
              padding: 5,
              marginLeft: 5,
              width: 250,
              textAlign: "center"
            }}
          >
            {error}
          </div>
        )) ||
          (warning && <div>{warning}</div>))}
    </div>
  );
};

export const renderInputPassword = ({
  input: { onChange, ...restInput },
  meta: { touched, error, warning }
}) => {
  return (
    <div>
      <input
        type="password"
        onChange={onChange}
        {...restInput}
        className="form-control"
      />
      {touched &&
        ((error && (
          <div
            style={{
              color: "#fff",
              borderBottomRightRadius: 5,
              borderBottomLeftRadius: 5,
              backgroundColor: "#CC0000",
              padding: 5,
              marginLeft: 5,
              width: 250,
              textAlign: "center"
            }}
          >
            {error}
          </div>
        )) ||
          (warning && <div>{warning}</div>))}
    </div>
  );
};

export const renderSelect = ({
  input: { onChange, ...restInput },
  meta: { touched, error, warning }
}) => {
  return (
    <div>
      <select className="form-control" onChange={onChange} {...restInput}>
        <option value="ADMIN">ADMIN</option>
        <option value="OPERADOR">OPERADOR</option>
        <option value="GESTOR">GESTOR</option>
      </select>
      {touched &&
        ((error && (
          <div
            style={{
              color: "#fff",
              borderBottomRightRadius: 5,
              borderBottomLeftRadius: 5,
              backgroundColor: "#CC0000",
              padding: 5,
              marginLeft: 5,
              width: 250,
              textAlign: "center"
            }}
          >
            {error}
          </div>
        )) ||
          (warning && <div>{warning}</div>))}
    </div>
  );
};
