import React, { Component } from "react";
import { Card, CardTitle, Modal, ModalBody, ModalHeader } from "mdbreact";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Loading } from "../../../config/loading";
import { toast } from "react-toastify";

import { getSemaphore } from "../../actions/semaphoreActions";

class Report extends Component {
  state = {
    modal: false,
    modalReportGeneral: false,
    startDate: moment(),
    endDate: moment(),
    semaphore: [],
    loading: true
  };

  componentDidMount() {
    this.props.getSemaphore().then(() => {
      this.setState({ loading: false });
    });
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.role !== "ADMIN" || user.role === "GESTOR") {
      this.props.history.push("/");
      toast.warn("Você não permissão para acessa essa página :(");
    }
  }

  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };

  toggle = semaphore => {
    this.setState({ modal: !this.state.modal, semaphore });
  };

  modalReportGeneral = semaphore => {
    this.setState({
      modalReportGeneral: !this.state.modalReportGeneral,
      semaphore
    });
  };

  sendReport = () => {
    this.props.history.push({
      pathname: `relatoriogerado/`,
      state: {
        semaphore: this.state.semaphore,
        startDate: this.state.startDate.format(),
        endDate: this.state.endDate.format()
      }
    });
  };

  sendReportGeneral = () => {
    this.props.history.push({
      pathname: `relatoriogeral/`,
      state: {
        startDate: this.state.startDate.format(),
        endDate: this.state.endDate.format()
      }
    });
  };

  render() {
    const { list } = this.props;
    return (
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        {Loading(this.state.loading)}
        <div className="container">
          <Card className="card-body">
            <button className="btn btn-new" onClick={this.modalReportGeneral}>
              <i className="fa fa-file-pdf-o"> </i> Relatório Geral
            </button>
            <CardTitle>Relatórios</CardTitle>
            <table className="table table-striped">
              <thead>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Semáforo</td>
                  <td style={{ fontWeight: "bold" }}>Instalação</td>
                  <td style={{ fontWeight: "bold" }}>Tensão do Sistema</td>
                  <td style={{ fontWeight: "bold" }}>Endereço</td>
                  <td style={{ fontWeight: "bold" }}>Acões</td>
                </tr>
              </thead>
              <tbody>
                {list.map((item, key) => (
                  <tr key={key}>
                    <th>{item.id}</th>
                    <th>
                      {item.month_of_installation}/{item.year_of_installation}
                    </th>
                    <th>{item.specified_network_voltage}V</th>
                    <th>{item.address}</th>
                    <th width="100">
                      <button
                        className="btn btn-sinape-secondary"
                        onClick={() => this.toggle(item)}
                        style={{
                          marginLeft: 8,
                          height: 33,
                          padding: 0,
                          width: 40,
                          marginTop: 0,
                          marginBottom: 0
                        }}
                      >
                        <i className="fa fa-file-pdf-o"> </i>
                      </button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Selecione um período:</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <label>Inicio</label>
                <DatePicker
                  className="form-control"
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  locale="pt-br"
                  dateFormat="DD/MM/YYYY"
                />
              </div>
              <div className="col-md-6">
                <label>Fim</label>
                <DatePicker
                  className="form-control"
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  locale="pt-br"
                  dateFormat="DD/MM/YYYY"
                />
              </div>
              <div className="col-md-12">
                <div className="form-group" />
                <button
                  className="btn custom-btn-send pull-right"
                  onClick={() => this.sendReport()}
                >
                  <i className="fa fa-file-pdf-o"> </i> Gerar Relatório
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalReportGeneral}
          toggle={this.modalReportGeneral}
        >
          <ModalHeader toggle={this.modalReportGeneral}>
            Selecione um período:
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <label>Inicio</label>
                <DatePicker
                  className="form-control"
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  locale="pt-br"
                  dateFormat="DD/MM/YYYY"
                />
              </div>
              <div className="col-md-6">
                <label>Fim</label>
                <DatePicker
                  className="form-control"
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  locale="pt-br"
                  dateFormat="DD/MM/YYYY"
                />
              </div>
              <div className="col-md-12">
                <div className="form-group" />
                <button
                  className="btn custom-btn-send pull-right"
                  onClick={() => this.sendReportGeneral()}
                >
                  <i className="fa fa-file-pdf-o"> </i> Gerar Relatório
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  list: state.semaphore.list
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getSemaphore }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Report);
