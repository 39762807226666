import api from "../../config/axios";

import OauthHelper from "../../config/OauthHelper";
const idUser = OauthHelper.getUserIdBase64();
export const AuthString = `Basic ${idUser}`;

export const getNotification = () => {
  return dispatch =>
    api
      .get("api/notification/", { headers: { Authorization: AuthString } })
      .then(resp => {
        dispatch({
          type: "GET_NOTIFICATION",
          payload: resp.data
        });
      });
};
