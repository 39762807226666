const INITIAL_STATE = {
  getreportbydate: [],
  getreportgeneral: [],
  list: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_REPORT":
      return { ...state, list: action.payload };
    case "GET_REPORT_BY_DATE":
      return { ...state, getreportbydate: action.payload };
    case "GET_REPORT_GENERAL":
      return { ...state, getreportgeneral: action.payload };
    default:
      return state;
  }
};
