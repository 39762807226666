import React, { Component } from "react";
import { Card, CardTitle, CardText } from "mdbreact";
export default class Admin extends Component {
  state = { user: [] };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ user });
  }

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.reload();
  };

  render() {
    const { user } = this.state;
    return (
      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <div className="container">
          <div className="home">
            <Card className="card-body">
              <CardTitle>Seja Bem-vindo</CardTitle>
              <CardText>Nome: {user.name}</CardText>
              <CardText>E-mail: {user.email}</CardText>
              <CardText>Permissão: {user.role}</CardText>
              <CardTitle>Legendas</CardTitle>
              <div className="row">
                <div className="col-md-6">
                  <h1>Status:</h1>
                  <div className="line">
                    <img
                      src={require("../../assets/imgs/status_ok.png")}
                      width="25"
                      alt="Status"
                    />
                    <p>Sistema funcionando perfeitamente</p>
                  </div>
                  <div className="line">
                    <img
                      src={require("../../assets/imgs/circle_yellow.png")}
                      width="25"
                      alt="Status"
                    />
                    <p>Atenção: subtensão ou sobretensão no sistema</p>
                  </div>
                  <div className="line">
                    <img
                      src={require("../../assets/imgs/status_broken.png")}
                      width="25"
                      alt="Status"
                    />
                    <p>Outages no sistema ou falha na comunicação</p>
                  </div>
                  <div className="line">
                    <img
                      src={require("../../assets/imgs/status_repair.png")}
                      width="25"
                      alt="Status"
                    />
                    <p>Compartimento aberto</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <h1>Rede Elétrica/Bateria:</h1>
                  <div className="line">
                    <img
                      src={require("../../assets/imgs/circle_green.png")}
                      width="25"
                      alt="Status"
                    />
                    <p>Tensão normal</p>
                  </div>
                  <div className="line">
                    <img
                      src={require("../../assets/imgs/circle_yellow.png")}
                      width="25"
                      alt="Status"
                    />
                    <p>Tensão precária ou crítica</p>
                  </div>
                  <div className="line">
                    <img
                      src={require("../../assets/imgs/circle_red.png")}
                      width="25"
                      alt="Status"
                    />
                    <p>Outage</p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
