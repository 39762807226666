import React, { Component } from "react";
import { Card, CardTitle } from "mdbreact";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSemaphore } from "../../actions/semaphoreActions";
import renderTh from "../../../config/renderThStatus";
import renderThNetwork from "../../../config/renderThNetwork";
import renderThBatteryStatus from "../../../config/renderThBatteryStatus";
import renderThBattery from "../../../config/renderThBattery";
import { Loading } from "../../../config/loading";

class Semaphore extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.props.getSemaphore().then(resp => {
      this.setState({ loading: false });
    });
  }

  pushSemaphoreDetail = item => {
    this.props.history.push({
      pathname: `/semaforodetalhe/${item.id}`,
      state: {
        semaphore: item
      }
    });
  };

  render() {
    const { list } = this.props;
    return (
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        {Loading(this.state.loading)}
        <div className="container">
          <Card className="card-body">
            <CardTitle>Semáforo</CardTitle>
            <table className="table table-striped">
              <thead>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Semáforo</td>
                  <td style={{ fontWeight: "bold", textAlign: "center" }}>
                    Status
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "center" }}>
                    Rede Elétrica
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "center" }}>
                    Bateria
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "center" }}>
                    N. Bateria
                  </td>
                  <td style={{ fontWeight: "bold" }}>Endereço</td>
                  <td style={{ fontWeight: "bold" }}>Ações</td>
                </tr>
              </thead>
              <tbody>
                {list.map((item, key) => (
                  <tr key={key}>
                    <th>{item.id}</th>
                    <th style={{ textAlign: "center" }}>
                      {item.status ? renderTh(item.status.code) : "-"}
                    </th>
                    <th style={{ textAlign: "center" }}>
                      {item.power_grid
                        ? renderThNetwork(item.power_grid.code)
                        : "-"}
                    </th>
                    <th style={{ textAlign: "center" }}>
                      {item.battery
                        ? renderThBatteryStatus(item.battery.code)
                        : "-"}
                    </th>
                    <th style={{ textAlign: "center" }}>
                      {item.battery_level
                        ? renderThBattery(item.battery_level)
                        : "-"}
                    </th>
                    <th>{item.address}</th>
                    <th width="100">
                      <button
                        className="btn btn-sinape-primary"
                        onClick={() => this.pushSemaphoreDetail(item)}
                        style={{
                          backgroundColor: "#999",
                          marginLeft: 8,
                          height: 30,
                          padding: 0,
                          width: 40,
                          marginTop: 0,
                          marginBottom: 0
                        }}
                      >
                        <i className="fa fa-search"> </i>
                      </button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  list: state.semaphore.list,
  detail: state.semaphore.detail
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getSemaphore }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Semaphore);
