import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoginPage from "./components/back-end/users/login";
import Admin from "./components/back-end/admin";
import Semaphore from "./components/back-end/semaphore/list";
import SemaphoreDetail from "./components/back-end/semaphore/detail";
import HeaderPage from "./components/back-end/useful/header";
import FooterPage from "./components/back-end/useful/footer";
import Notification from "./components/back-end/notification/list";
import User from "./components/back-end/users/list";
import Report from "./components/back-end/report/list";
import ReportDetail from "./components/back-end/report/detail";
import ReportGeneral from "./components/back-end/report/report_general";
import NotificationDetail from "./components/back-end/notification/detail";
import FormAddUser from "./components/back-end/users/formAdd";

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <div>
      <HeaderPage />
      <Route
        {...rest}
        render={props =>
          localStorage.getItem("token") ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      />
      <FooterPage />
    </div>
  );
}
class App extends Component {
  state = {
    authed: false
  };
  render() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route path="/" exact component={LoginPage} />
            <PrivateRoute
              path="/admin"
              authed={this.state.authed}
              component={Admin}
            />
            <PrivateRoute
              path="/semaforo"
              authed={this.state.authed}
              component={Semaphore}
            />
            <PrivateRoute
              path="/notificacoes"
              authed={this.state.authed}
              component={Notification}
            />
            <PrivateRoute
              path="/relatorios"
              authed={this.state.authed}
              component={Report}
            />
            <PrivateRoute
              path="/usuarios"
              authed={this.state.authed}
              component={User}
            />
            <PrivateRoute
              path="/novousuario/"
              authed={this.state.authed}
              component={FormAddUser}
            />
            <PrivateRoute
              path="/notificacaodetalhe"
              authed={this.state.authed}
              component={NotificationDetail}
            />
            <PrivateRoute
              path="/semaforodetalhe/:semaforoId"
              authed={this.state.authed}
              component={SemaphoreDetail}
            />
            <PrivateRoute
              path="/relatoriogerado"
              authed={this.state.authed}
              component={ReportDetail}
            />
            <PrivateRoute
              path="/relatoriogeral"
              authed={this.state.authed}
              component={ReportGeneral}
            />
            <Route render={() => <h3>No Match</h3>} />
          </Switch>
          <ToastContainer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
