import React from "react";

const renderTh = code => {
  if (code === 500) {
    return (
      <img
        src={require("../assets/imgs/status_broken.png")}
        width="25"
        alt="Status"
      />
    );
  } else if (code === 501) {
    return (
      <div>
        <img
          src={require("../assets/imgs/status_broken.png")}
          width="25"
          alt="Status"
        />
        <img
          src={require("../assets/imgs/status_repair.png")}
          width="25"
          alt="Status"
        />
      </div>
    );
  } else if (code === 502) {
    return (
      <div>
        <img
          src={require("../assets/imgs/circle_yellow.png")}
          width="25"
          alt="Status"
        />
      </div>
    );
  } else if (code === 503) {
    return (
      <div>
        <img
          src={require("../assets/imgs/circle_yellow.png")}
          width="25"
          alt="Status"
        />
        <img
          src={require("../assets/imgs/status_repair.png")}
          width="25"
          alt="Status"
        />
      </div>
    );
  } else if (code === 504) {
    return (
      <div>
        <img
          src={require("../assets/imgs/status_ok.png")}
          width="25"
          alt="Status"
        />
        <img
          src={require("../assets/imgs/status_repair.png")}
          width="25"
          alt="Status"
        />
      </div>
    );
  } else if (code === 505) {
    return (
      <div>
        <img
          src={require("../assets/imgs/status_ok.png")}
          width="25"
          alt="Status"
        />
      </div>
    );
  }
};

export default renderTh;
